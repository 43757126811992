<template>
  <div class="app-container">
    <div class="header">
      <div class="left">
        <span>留下手机号，让助教联系您</span>
        <em>如未成功添加助教微信，留下手机号我们将联系您</em>
      </div>
      <router-link tag="div" class="right" :to="{name: 'WechatSubmitPhone', query: {...$route.query}}">去填写</router-link>
    </div>
    <div class="tips">
      <span />
      <em>支付成功！</em>
    </div>
    <div class="content">
      <div class="title">不添加老师微信无法学习课程！</div>
      <div class="text">方法一：长按识别二维码，打开对方名片并加好友</div>
      <div class="qr-code">
        <img :src="oss2cdn(info.wxUrl)" alt="">
      </div>
      <div class="fingerprint" />
      <div class="text finger-arrow">
        <span />
        <em>长按识别二维码添加助教，进群学习</em>
      </div>
      <div class="copy-wrap">
        <div class="text">方法二：复制并搜索助教老师微信号</div>
        <div class="copy-box">
          <div class="wx-number">{{ info.wx }}</div>
          <div class="copy-btn" :data-clipboard-text="info.wx">一键复制</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Landing} from '@/network'
import {Toast} from 'vant'
import Clipboard from 'clipboard'
import {oss2cdn} from '@/utils'

export default {
  data() {
    return {
      info: {},
      oss2cdn
    }
  },
  created() {
    this.handleGetSalesmanInfo()
  },
  methods: {
    async handleGetSalesmanInfo() {
      const {data} = await Landing.getSalesmanInfo({id: this.$route.params.salesmanId})
      this.info = data
      this.handleSetReadFinish()
      this.handleClipboard()
    },
    handleClipboard() {
      const clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', () => {
        Toast('复制成功')
      })
    },
    handleSetReadFinish() {
      const outTradeNo = this.$route.query.outTradeNo
      setTimeout(() => {
        Landing.setReadFinish({outTradeNo})
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #F4F4F4;
}
.header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 46px;
  .left {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    span {
      width: auto;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin-bottom: 4px;
    }
    em {
      width: auto;
      line-height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-style: normal;
      color: #000000;
    }
  }
  .right {
    width: 96px;
    height: 34px;
    background: linear-gradient(90deg, #FEC502 0%, #FE9161 100%);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
  }
}
.tips {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 38px;
  span {
    width: 21px;
    height: 21px;
    margin-right: 2px;
    background-image: url('~@/assets/images/money-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  em {
    width: auto;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    font-style: normal;
  }
}
.content {
  width: 342px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0px;
  .title {
    width: auto;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #F84439;
    line-height: 25px;
    margin-bottom: 19px;
  }
  .text {
    width: auto;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
  }
  .qr-code {
    width: 162px;
    height: 162px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #EAEAEA;
    margin-bottom: 9px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .fingerprint {
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/fingerprint-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 12px;
  }
  .finger-arrow {
    margin-bottom: 40px;
    span {
      width: 16px;
      height: 16px;
      background-image: url('~@/assets/images/finger-arrow-icon.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-right: 1px;
    }
    em {
      font-style: normal;
    }
  }
  .copy-wrap {
    width: 300px;
    height: auto;
    background: #F4F4F4;
    box-sizing: border-box;
    padding: 10px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .text {
      margin-bottom: 18px;
    }
    .copy-box {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .wx-number {
        width: 132px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 18px;
        box-sizing: border-box;
        padding: 0 6px;
        border: 1px solid #BEBEBE;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-right: 12px;
        overflow:hidden; //超出的文本隐藏
        text-overflow:ellipsis; //溢出用省略号显示
        white-space:nowrap; //溢出不换行
      }
      .copy-btn {
        width: 79px;
        height: 23px;
        background: #F84439;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
      }
    }
  }
}
</style>
